const ShippingIcon = ({width="90px", height="90px"}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 90 90"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>送货</title>
      <g
        id="NEW"
        stroke="none"
        stroke-width="1"
        fill="none"
        fill-rule="evenodd"
      >
        <g id="HOMEPAGE" transform="translate(-583.000000, -1177.000000)">
          <g id="编组-11" transform="translate(108.000000, 740.000000)">
            <g id="送货" transform="translate(475.000000, 437.000000)">
              <rect id="矩形" x="0" y="0" width="90" height="90"></rect>
              <path
                d="M23.0054515,17.5831611 L16.1425143,20.8808526 C15.5538868,21.1636924 15.3033058,21.8681596 15.5810965,22.4591867 L16.1609461,23.6928741 L16.1609461,23.6928741 L21.2629073,21.2413291 C21.6577128,21.051621 22.1315548,21.2178853 22.3212629,21.6126908 C22.3222428,21.6147301 22.323214,21.6167736 22.3241764,21.6188212 L39.2822699,57.6984127 L39.2822699,57.6984127 C40.2707806,57.0648163 41.3535178,56.5779291 42.5079365,56.2552859 L24.5973516,18.1493983 C24.3178668,17.554777 23.6092637,17.2993083 23.0146424,17.5787931 C23.0115725,17.580236 23.0085089,17.581692 23.0054515,17.5831611 Z"
                id="Fill-331"
                fill="#0081ED"
              ></path>
              <path
                d="M72.6710645,55.355101 L71.7768025,53.3719473 C71.5967465,52.9726474 71.1270857,52.7949151 70.7277858,52.9749711 C70.718021,52.9793743 70.7083465,52.9839749 70.698768,52.98877 L55.7142857,60.4902923 L55.7142857,60.4902923 C56.4593645,61.4864535 57.0678356,62.5951149 57.5158374,63.7936508 L72.3031267,56.3903084 C72.6836172,56.1998136 72.8459797,55.7430004 72.6710645,55.355101 Z"
                id="Fill-333"
                fill="#0081ED"
              ></path>
              <path
                d="M53.5600126,58.7142857 L75.9628243,48.2151193 C76.5577585,47.936301 76.8140211,47.2279847 76.5352028,46.6330505 C76.5340242,46.6305356 76.5328368,46.6280249 76.5316406,46.6255183 L65.3567313,23.2090422 C65.0751503,22.6190032 64.3701997,22.3667528 63.7782075,22.6442039 L36.4816002,35.4373985 C35.8866705,35.7162262 35.6304192,36.4245466 35.9092469,37.0194764 C35.9104237,37.0219873 35.9116093,37.0244941 35.9128036,37.0269967 L44.9155855,55.8916412 L44.9155855,55.8916412 C45.1754275,55.8742425 45.4329285,55.8510443 45.6962819,55.8510443 C48.696169,55.8510443 51.4379699,56.9320817 53.5600126,58.7142857 Z"
                id="Fill-335"
                fill="#8CB44A"
              ></path>
              <path
                d="M52.9479781,25.0328492 L35.6821954,32.9919751 C35.289989,33.1727731 34.8252184,33.0065285 34.6366573,32.6179947 L29.6548118,22.3528049 C29.4635671,21.9587414 29.6279842,21.4842553 30.0220477,21.2930105 C30.0267705,21.2907185 30.031516,21.2884734 30.0362834,21.2862756 L47.3041584,13.3255879 C47.6964305,13.1447459 48.1612869,13.3110726 48.3497948,13.6997191 L53.329548,23.9664708 C53.5207042,24.3605773 53.3561805,24.8350264 52.962074,25.0261825 C52.9573973,25.0284509 52.9526985,25.0306732 52.9479781,25.0328492 Z"
                id="Fill-337"
                fill="#8CB44A"
              ></path>
              <path
                d="M12.8249888,25.9067385 L20.4021092,22.5810638 C20.8031955,22.405023 20.9856309,21.937169 20.8095901,21.5360827 C20.8030322,21.5211413 20.7960147,21.5064058 20.7885477,21.4918973 L19.3734929,18.7424269 C19.1817196,18.3698086 18.7331493,18.210695 18.349439,18.379182 L10.7724467,21.7062345 C10.3713883,21.882339 10.1890272,22.3502219 10.3651317,22.7512803 C10.3716929,22.7662227 10.3787137,22.7809591 10.3861842,22.7954683 L11.8011108,25.5435609 C11.9929009,25.9160585 12.4413431,26.0751245 12.8249888,25.9067385 Z"
                id="Fill-339"
                fill="#8CB44A"
              ></path>
              <polygon
                id="Fill-341"
                fill="#8CB44A"
                points="28.4240414 36.3650794 30.3174603 35.6974756 28.1480947 32.3015873 26.2539683 32.9707693"
              ></polygon>
              <polygon
                id="Fill-343"
                fill="#8CB44A"
                points="35.5359085 51.6031746 37.4285714 50.9339926 35.2598657 47.5396825 33.3650794 48.2072863"
              ></polygon>
              <path
                d="M45.5552735,74.6202286 C41.8191549,74.6202286 38.7915618,71.5940439 38.7915618,67.8571429 C38.7915618,64.1208059 41.8191549,61.0923649 45.5552735,61.0923649 C49.2913921,61.0923649 52.3201134,64.1208059 52.3201134,67.8571429 C52.3201134,71.5940439 49.2913921,74.6202286 45.5552735,74.6202286 M45.5552735,58.7142857 C40.5053363,58.7142857 36.4126984,62.8071089 36.4126984,67.8571429 C36.4126984,72.9071768 40.5053363,77 45.5552735,77 C50.6057748,77 54.6984127,72.9071768 54.6984127,67.8571429 C54.6984127,62.8071089 50.6057748,58.7142857 45.5552735,58.7142857"
                id="Fill-345"
                fill="#0081ED"
              ></path>
              <path
                d="M50.6349206,67.3492063 C50.6349206,69.8739457 48.5879685,71.9206349 46.0629047,71.9206349 C43.5378409,71.9206349 41.4920635,69.8739457 41.4920635,67.3492063 C41.4920635,64.8227051 43.5378409,62.7777778 46.0629047,62.7777778 C48.5879685,62.7777778 50.6349206,64.8227051 50.6349206,67.3492063"
                id="Fill-347"
                fill="#0081ED"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default ShippingIcon;
