import { Typography } from "@mui/material";

export default function TypoBold({ children, ...props }) {
  return (
    <Typography
      variant="h2"
      fontWeight="bold"
      textAlign="left"
      lineHeight="50px"
      sx={{
        fontSize: { xs: "18px", sm: "2em", md: "2.5em" },
      }}
      {...props}
    >
      {children}
    </Typography>
  );
}
