import { useState } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import MenuIcon from "@mui/icons-material/Menu";
import Button from "@mui/material/Button";
import {
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Popover,
  Stack,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { ArrowDropDown } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { useBreakpoints } from "../../../app/hooks/useBreakpoints";
import { THEME_COLOR } from "../../../app/constants/constants";
import { changeLan, selectLan } from "../../../Home/headerSlice";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import SearchBar from "./SearchBar";

const drawerWidth = 240;
const navItems = [
  { title: "ToDoor.toDoor", link: "/toDoor" },
  { title: "Header.lockerPudo", link: "/pickUpPoint" },
  { title: "Header.support", link: "/atencionCliente" },
];

export default function CustomHeader(props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [empAnchorEl, setEmpAnchorEl] = useState(null);
  const [hojaAnchorEl, setHojaAnchorEl] = useState(null);
  const currentLan = useSelector(selectLan);
  const [t, i18n] = useTranslation("global");
  const { xs } = useBreakpoints;
  const [lan, setLan] = useState(currentLan);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };
  const dispatch = useDispatch();

  const closeDrawer = () => {
    setMobileOpen(false);
  };

  const handleClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleEmpClick = (event) => {
    event.stopPropagation();
    setEmpAnchorEl(event.currentTarget);
  };

  const handleHojaClick = (event) => {
    event.stopPropagation();
    setHojaAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleEmpClose = () => {
    setEmpAnchorEl(null);
  };

  const handleHojaClose = () => {
    setHojaAnchorEl(null);
  };

  const handleChangeLan = (item) => {
    switch (item) {
      case "English":
        dispatch(changeLan("en"));
        i18n.changeLanguage("en");
        setLan("en");
        break;
      case "Español":
        dispatch(changeLan("es"));
        i18n.changeLanguage("es");
        setLan("es");
        break;
      case "Portuguese":
        dispatch(changeLan("pt"));
        i18n.changeLanguage("pt");
        setLan("pt");
        break;
      default:
        break;
    }
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
      <Typography variant="h6" sx={{ my: 2 }}>
        MENU
      </Typography>
      <Divider />
      <List>
        {navItems.map((item) => (
          <ListItem key={item.title} disablePadding>
            {item.title === "Header.languaje" ? (
              <ListItemButton
                onClick={(e) => {
                  handleClick(e);
                }}
                endIcon={<ArrowDropDown />}
                sx={{ textTransform: "none", display: "flex" }}
              >
                <Box
                  component="img"
                  src={
                    lan === "en"
                      ? "https://img.icons8.com/office/30/000000/usa.png"
                      : lan === "es"
                      ? "https://img.icons8.com/office/30/000000/spain-2.png"
                      : lan === "pt"
                      ? "https://img.icons8.com/office/30/000000/portugal.png"
                      : ""
                  }
                  alt="current language"
                  sx={{ width: 20, height: 20 }}
                />
                <ArrowDropDown style={{ marginLeft: 8 }} />
              </ListItemButton>
            ) : (
              <ListItemButton
                sx={{ textAlign: "center", cursor: "pointer" }}
                component="a"
                href={item.link}
              >
                <ListItemText primary={t(item.title)} />
              </ListItemButton>
            )}
          </ListItem>
        ))}
      </List>
    </Box>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  const open = Boolean(anchorEl);
  const empOpen = Boolean(empAnchorEl);
  const hojaOpen = Boolean(hojaAnchorEl);

  const id = hojaOpen ? "simple-popover" : undefined;

  return (
    <Box display="flex" justifyContent="space-between">
      <CssBaseline />
      <AppBar
        component="nav"
        sx={{
          backgroundColor: "#1B5FC0",
          backgroundImage: 'url("/home/homeBanner.png")',
          backgroundRepeat: "no-repeat",
          backgroundPosition: "bottom",
          backgroundSize: "cover",
          height: { xs: "500px", sm: "624px" },
          boxShadow: "none",
          position: "relative",
        }}
      >
        <Box
          flexWrap="wrap"
          width="90%"
          sx={{
            position: "absolute",
            left: { xs: 20, sm: 50, lg: 108 },
            top: { xs: 160, sm: 200 },
          }}
        >
          <Typography
            variant="h1"
            fontSize="50px"
            width="350px"
            color="#C9FF73"
            sx={{ pb: { xs: 0, sm: 3 }, mt: { xs: 3, sm: 0 } }}
          >
            {t("Banner.title-banner1")}
          </Typography>
          <Typography
            variant="h1"
            fontSize="50px"
            width="350px"
            color="#C9FF73"
            sx={{
              pb: { xs: 0, sm: 3 },
            }}
          >
            {t("Banner.title-banner2")}
          </Typography>
          <Typography variant="h4" color="white" sx={{ pb: { xs: 0, sm: 3 } }}>
            {t("Banner.content-banner1")}
          </Typography>
        </Box>
        <SearchBar />
        <Toolbar
          display="flex"
          justifyContent="space-between"
          sx={{
            borderBottom: "1px solid rgba(255, 255, 255, 0.4)",
          }}
        >
          <Box width="100%">
            <Box gap={2} display="flex" alignItems="center" width="100%">
              <Box display="flex" gap={1}>
                <AccessTimeIcon />
                <Typography
                  variant="subtitle1"
                  sx={{ opacity: "40%", color: "white" }}
                >
                  {t("Customer.time")}
                </Typography>
              </Box>
              <Box display="flex" gap={1} alignItems="center">
                <SupportAgentIcon />
                <Box
                  component="a"
                  href={t("ChatBot.urlChat")}
                  sx={{ "&:hover": { opacity: "0.5" } }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{ opacity: "40%", color: "white" }}
                  >
                    {"Chat"}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
          <Button
            component="div"
            onClick={(e) => {
              handleClick(e);
            }}
            disableRipple
          >
            <Box
              component="img"
              src={
                lan === "en"
                  ? "https://img.icons8.com/office/30/000000/usa.png"
                  : lan === "es"
                  ? "https://img.icons8.com/office/30/000000/spain-2.png"
                  : lan === "pt"
                  ? "https://img.icons8.com/office/30/000000/portugal.png"
                  : ""
              }
              sx={{
                "&:hover": {
                  color: THEME_COLOR,
                },
              }}
            />
            <ArrowDropDown />
          </Button>
        </Toolbar>
        <Toolbar sx={{ position: "relative" }}>
          <IconButton
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mx: 1, display: { xs: "block", md: "none" }, color: "white" }}
          >
            <MenuIcon />
          </IconButton>
          <Box
            component="a"
            href="/"
            sx={{
              flexGrow: 1,
              display: "block",

              pt: 1,
            }}
          >
            <Box
              component="img"
              src="/logo.png"
              alt="logo"
              width="200px"
              height="50px"
            />
          </Box>
          <Box
            sx={{ display: { xs: "none", md: "flex" }, mt: "10px" }}
            gap={10}
          >
            {navItems.map((item) =>
              item.title === "Header.support" ? (
                <Button
                  component="div"
                  onClick={(e) => {
                    handleEmpClick(e);
                  }}
                  disableRipple
                >
                  <Typography
                    variant="subtitle1"
                    fontWeight="bold"
                    sx={{
                      color: "white",
                      "&:hover": {
                        color: THEME_COLOR,
                        fontWeight: "bold",
                      },
                    }}
                  >
                    {t(item.title)}
                  </Typography>
                  <ArrowDropDown />
                </Button>
              ) : (
                <Button
                  component="a"
                  href={item.link}
                  key={item.title}
                  disableRipple
                >
                  <Typography
                    variant="subtitle1"
                    fontWeight="bold"
                    sx={{
                      color: "white",
                      "&:hover": {
                        color: THEME_COLOR,
                        fontWeight: "bold",
                      },
                    }}
                  >
                    {t(item.title)}
                  </Typography>
                </Button>
              )
            )}
          </Box>
        </Toolbar>
      </AppBar>
      <nav>
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", md: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
      </nav>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        {/* Menu Items */}
        <MenuItem
          onClick={() => {
            handleChangeLan("Español");
            handleClose();
            xs && closeDrawer();
          }}
        >
          <ListItemIcon>
            <img
              src="https://img.icons8.com/office/30/000000/spain-2.png"
              alt="Español"
              style={{ width: 20, height: 20 }}
            />
          </ListItemIcon>
          Español
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleChangeLan("Portuguese");
            handleClose();
            xs && closeDrawer();
          }}
        >
          <ListItemIcon>
            <img
              src="https://img.icons8.com/office/30/000000/portugal.png"
              alt="Português"
              style={{ width: 20, height: 20 }}
            />
          </ListItemIcon>
          Português
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleChangeLan("English");
            handleClose();
            xs && closeDrawer();
          }}
        >
          <ListItemIcon>
            <img
              src="https://img.icons8.com/office/30/000000/usa.png"
              alt="English"
              style={{ width: 20, height: 20 }}
            />
          </ListItemIcon>
          English
        </MenuItem>
      </Menu>
      {/* sep */}
      <Menu
        anchorEl={empAnchorEl}
        open={empOpen}
        onClose={handleEmpClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <MenuItem>
          <Button href="/atencionCliente">
            <Typography variant="body2">{t("Header.support")}</Typography>
          </Button>
        </MenuItem>
        <MenuItem>
          <Stack gap={1}>
            <Button
              aria-describedby={id}
              variant="contained"
              onClick={handleHojaClick}
            >
              {t("Header.ticket")}
            </Button>
            <Button
              aria-describedby={id}
              variant="contained"
              href="http://www.livroreclamacoes.pt"
              rel="noopener noreferrer"
            >
              Portugal: livroreclamacoes
            </Button>
          </Stack>
          <Popover
            id={id}
            open={hojaOpen}
            anchorEl={hojaAnchorEl}
            onClose={handleHojaClose}
            anchorReference="anchorPosition"
            anchorPosition={{ top: 240, left: 1470 }}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <MenuItem>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="/hojas/andalucia.pdf"
                download
              >
                Andalucía
              </a>
            </MenuItem>
            <MenuItem>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="/hojas/aragon.pdf"
                download
              >
                Aragón
              </a>
            </MenuItem>
            <MenuItem>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="/hojas/asturias.pdf"
                download
              >
                Asturias
              </a>
            </MenuItem>
            <MenuItem>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="/hojas/baleares.pdf"
                download
              >
                Islas Baleares
              </a>
            </MenuItem>
            <MenuItem>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="/hojas/canarias.pdf"
                download
              >
                Canarias
              </a>
            </MenuItem>
            <MenuItem>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="/hojas/cantabria.pdf"
                download
              >
                Cantabria
              </a>
            </MenuItem>
            <MenuItem>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="/hojas/castillaLaMancha.pdf"
                download
              >
                Castilla-La Mancha
              </a>
            </MenuItem>
            <MenuItem>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="/hojas/castillaLeon.pdf"
                download
              >
                Castilla y León
              </a>
            </MenuItem>
            <MenuItem>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="/hojas/catalunya.pdf"
                download
              >
                Cataluña
              </a>
            </MenuItem>
            <MenuItem>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="/hojas/extremadura.pdf"
                download
              >
                Extremadura
              </a>
            </MenuItem>
            <MenuItem>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="/hojas/galicia.pdf"
                download
              >
                Galicia
              </a>
            </MenuItem>
            <MenuItem>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="/hojas/laRioja.pdf"
                download
              >
                La Rioja
              </a>
            </MenuItem>
            <MenuItem>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="/hojas/madrid.pdf"
                download
              >
                Comunidad de Madrid
              </a>
            </MenuItem>
            <MenuItem>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="/hojas/murcia.pdf"
                download
              >
                Región de Murcia
              </a>
            </MenuItem>
            <MenuItem>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="/hojas/navarra.pdf"
                download
              >
                Navarra
              </a>
            </MenuItem>
            <MenuItem>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="/hojas/paisVasco.pdf"
                download
              >
                País Vasco
              </a>
            </MenuItem>
            <MenuItem>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="/hojas/valencia.pdf"
                download
              >
                Comunidad Valenciana
              </a>
            </MenuItem>
            <MenuItem>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="/hojas/ceuta.pdf"
                download
              >
                Ceuta
              </a>
            </MenuItem>
            <MenuItem>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="/hojas/melilla.pdf"
                download
              >
                Melilla
              </a>
            </MenuItem>
          </Popover>
        </MenuItem>
      </Menu>
    </Box>
  );
}
