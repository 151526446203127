import { Box } from "@mui/material";

export default function Card({ width, height, color, children }) {
  return (
    <Box
      sx={{
        width,
        height,
        borderRadius: "36px 16px 36px 16px",
        position: "relative",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "flex-start",
        gap: "16px",
        padding: "40px 40px 56px 40px",
      }}
      bgcolor={color}
    >
      {children}
    </Box>
  );
}
