import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";

export default function ChatWithUs() {
  const [t, i18n] = useTranslation("global");

  return (
    <Box
      component="a"
      // href={`https://echo.sg.cainiao.com/p/emailTask/officialForm/create?LAYOUT=NO&lang=${i18n.language}-ES&id=ecoscooting`}
      href={t("ChatBot.urlChat")}
      sx={{
        position: "fixed",
        bottom: 100,
        right: {xs: 40, sm: 20},
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "50px",
        height: "50px",
        transition: "width 0.3s ease",
        cursor: "pointer",
        "&:hover": {
          transform: "scale(1.5)",
        },
      }}
    >
      <Box
        component="img"
        src="/chatBot.png"
        alt="chatWithUs"
        sx={{ width: "50px", height: "50px" }}
        // sx={{
        //   display: "flex",
        //   alignItems: "center",
        //   justifyContent: "center",
        //   width: "50px",
        //   height: "50px",
        //   borderRadius: "50%",
        //   mt: 1,
        //   flexShrink: 0,
        // }}
      />
    </Box>
  );
}
