const TimeIcon = ({width="90px", height="90px"}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 90 90"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>时间 (1)</title>
      <g id="NEW" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="HOMEPAGE" transform="translate(-583.000000, -933.000000)">
          <g id="编组-11" transform="translate(108.000000, 740.000000)">
            <g id="时间-(1)" transform="translate(475.000000, 193.000000)">
              <rect id="矩形" x="0" y="0" width="90" height="90"></rect>
              <path
                d="M45,69 C58.2546,69 69,58.2546 69,45 C69,31.7454 58.2546,21 45,21 C31.7454,21 21,31.7454 21,45 C21,58.2546 31.7454,69 45,69 Z M34.7274,52.7274 L43.2,44.2542 L43.2,34.2 C43.2,33.2058875 44.0058875,32.4 45,32.4 C45.9941125,32.4 46.8,33.2058875 46.8,34.2 L46.8,45 C46.8,45.4773507 46.6101889,45.9351131 46.2726,46.2726 L37.2726,55.2726 C36.820666,55.7405218 36.1514198,55.9281827 35.5220868,55.7634549 C34.8927538,55.5987271 34.4012729,55.1072462 34.2365451,54.4779132 C34.0718173,53.8485802 34.2594782,53.179334 34.7274,52.7274 L34.7274,52.7274 Z"
                id="形状"
                fill="#4EA7F3"
                fillRule="nonzero"
              ></path>
              <path
                d="M45.0002898,77 C27.3553792,77 13,62.645199 13,45.0006158 C13,36.4577731 16.3251006,28.424567 22.3631645,22.3805737 C22.687561,22.0553046 23.1194618,21.8762979 23.5793979,21.8762979 C24.038537,21.8762979 24.4702206,22.054725 24.7946171,22.3794871 C25.4650704,23.0492952 25.4657224,24.1397799 24.7961384,24.81024 C19.4065053,30.2050715 16.4384727,37.3754088 16.4384727,45.0006158 C16.4384727,60.7492204 29.2511936,73.5617096 45.0002898,73.5617096 C60.7486615,73.5617096 73.5613824,60.7492204 73.5613824,45.0006158 C73.5613824,29.8182982 61.809144,17.38599 46.7192726,16.489725 L46.7192726,26.0746605 C46.7192726,27.0227946 45.948197,27.7941679 45.0002898,27.7941679 C44.0520203,27.7941679 43.280655,27.0227946 43.280655,26.0746605 L43.280655,13 L45.0002898,13 C53.534135,13 61.5663175,16.3324512 67.6172761,22.3831092 C73.6678001,28.434057 77,36.4663214 77,45.0006158 C77,62.645199 62.6448381,77 45.0002898,77 L45.0002898,77 Z"
                id="形状"
                fill="#CCF495"
                fillRule="nonzero"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default TimeIcon;
