const ShipperIcon = ({width="90px", height="90px"}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 90 90"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>派送</title>
      <g
        id="NEW"
        stroke="none"
        stroke-width="1"
        fill="none"
        fill-rule="evenodd"
      >
        <g id="HOMEPAGE" transform="translate(-1215.000000, -926.000000)">
          <g id="编组-11" transform="translate(108.000000, 740.000000)">
            <g id="派送" transform="translate(1107.000000, 186.000000)">
              <rect id="矩形" x="0" y="0" width="90" height="90"></rect>
              <path
                d="M64.6003593,32.0609217 C68.8902538,31.6042972 70.5821068,33.2954991 70.8457527,37 C74.3198517,35.7838721 77,34.8475612 77,34.8475612 L70.6830455,17 L51,23.7586578 C52.1336771,27.4047354 53.1844941,30.2782412 54.3671341,33.61529 C55.689883,33.377753 60.9763588,32.444517 64.6003593,32.0609217"
                id="Fill-321"
                fill="#CCF495"
              ></path>
              <path
                d="M68.9310855,36.479184 C68.518169,34.2216577 66.3654276,32.7323121 64.120006,33.143323 L54.3041984,34.9617805 L48.7765432,27.5704103 C48.1631965,24.9670888 45.8650301,23.0181997 43.0891465,23 L43.0499647,23 C39.8091733,23 37.1696727,25.632896 37.1485748,28.8997498 L37,49.8840525 C36.979038,53.0439827 39.4173548,54.788883 42.5142282,57.5446273 L51.2638373,65.4326989 L53.1792271,79.4108592 C53.4648025,81.4916964 55.2355209,83 57.2669493,83 C57.4545701,83 57.6444513,82.9878668 57.8358396,82.9605672 C60.0963311,82.64738 61.6764147,80.5498597 61.3637134,78.2741336 L59.2411119,62.7853947 C59.1069894,61.8109502 58.6337931,60.9153712 57.9051614,60.2571472 L48.7675012,52.0187306 C48.7742827,51.9049822 48.8021621,51.7980587 48.8029156,51.6843103 L48.8526464,44.0722681 C48.6062528,43.6620156 45.4822535,38.4553727 43.6429669,34.530295 L49.2399439,42.014939 C50.028102,43.070524 51.2570558,43.6733905 52.539508,43.6733905 C52.7874086,43.6733905 53.0390766,43.6506408 53.2877307,43.6036248 L65.6149444,41.3210738 C67.858859,40.905513 69.3440019,38.7389854 68.9310855,36.479184"
                id="Fill-323"
                fill="#0081ED"
              ></path>
              <path
                d="M37.4561289,55 L27.3574305,78.0817425 C26.4241168,80.21007 27.3818309,82.6990137 29.4939869,83.6401527 C30.0422325,83.8846643 30.6164034,84 31.1783741,84 C32.7849852,84 34.3161077,83.0611677 35.0069429,81.4856825 L44,60.93056 C42.2088612,59.5895906 39.471446,57.3666879 37.4561289,55"
                id="Fill-325"
                fill="#0081ED"
              ></path>
              <path
                d="M43.4168414,22.9906077 C43.5426427,22.9976519 43.6684441,23 43.7950219,23 C48.1871961,23 51.7880644,19.5350078 51.9907443,15.1127793 C52.0412201,14.039704 51.8843568,12.9861961 51.5418977,12 L36,17.3035435 C36.356437,18.4000996 36.9334147,19.4199516 37.7278266,20.2989185 C39.2048272,21.9324036 41.2238606,22.8904227 43.4168414,22.9906077"
                id="Fill-327"
                fill="#0081ED"
              ></path>
              <path
                d="M54.3212995,9.46237582 L35.3720965,16 C35.3720965,16 33.7254761,10.5776086 37.3599892,8.15378523 C40.3052368,6.18747372 43.3049983,5.5358428 45.9410845,6.32982767 C48.3411924,7.05149368 49.8205371,9.19441544 49.8205371,9.19441544 C49.8205371,9.19441544 54.3212995,8.28395916 54.6147788,8.44915064 C55.4862555,8.93635133 54.6528639,9.27358555 54.3212995,9.46237582"
                id="Fill-329"
                fill="#CCF495"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default ShipperIcon;
