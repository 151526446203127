import { Box, Stack, Typography } from "@mui/material";
import Card from "../../../components/Card";
import { useBreakpoints } from "../../../app/hooks/useBreakpoints";
import { useTranslation } from "react-i18next";

export default function Sections2() {
  const [t] = useTranslation("global");
  const { md, lg } = useBreakpoints();

  return (
    <Stack
      width="100%"
      bgcolor="#DEF0FF"
      sx={{
        background: "white",
      }}
      mb={12}
    >
      <Stack
        padding={!md ? "0 28px" : "0 108px"}
        alignItems="center"
        gap={!md ? "10px" : "150px"}
      >
        <Typography
          variant="h2"
          sx={{ fontSize: { xs: "28px", md: "48px" } }}
          textAlign="center"
        >
          {t("Home.proud")}
        </Typography>
        <Box
          pt={!md ? 14 : 0}
          display="flex"
          pl={!md ? 4 : 0}
          gap={!lg ? 18 : 5}
          flexWrap="no-wrap"
          flexDirection={!lg ? "column" : "row "}
        >
          <Card
            width={!md ? "264px" : "342px"}
            height={!md ? "142px" : "336px"}
            color="#3D5366"
          >
            <Typography
              variant="body2"
              fontWeight={400}
              color="white"
              sx={{
                fontSize: { xs: "11px", md: "18px" },
                mt: { xs: "90px", md: "227px" },
              }}
            >
              {t("Home.proudDesc1")}
            </Typography>
            <CustomCard
              width={!md ? "264px" : "342px"}
              height={!md ? "142px" : "336px"}
              bgUrl="/domicilio.png"
            >
              <Stack sx={{ gap: { xs: 0, md: 1 } }}>
                <Typography
                  variant="h5"
                  color="white"
                  sx={{
                    fontSize: { xs: "18px", md: "21px" },
                    mt: { xs: "200px", md: "280px" },
                  }}
                >
                  {t("ToDoor.toDoor")}
                </Typography>
                <UnderLine />
              </Stack>
            </CustomCard>
          </Card>
          <Card
            width={!md ? "264px" : "342px"}
            height={!md ? "142px" : "336px"}
            color="#198DEF"
          >
            <Typography
              variant="body2"
              fontWeight={400}
              color="white"
              sx={{
                fontSize: { xs: "10px", md: "16px" },
                mt: { xs: "100px", md: "227px" },
              }}
            >
              {t("Home.proudDesc2")}
            </Typography>
            <CustomCard
              width={!md ? "264px" : "342px"}
              height={!md ? "142px" : "336px"}
              bgUrl="/locker.png"
            >
              <Stack sx={{ gap: { xs: 0, md: 1 } }}>
                <Typography
                  variant="h5"
                  color="white"
                  sx={{
                    fontSize: { xs: "18px", md: "21px" },
                    mt: { xs: "200px", md: "280px" },
                  }}
                >
                  {t("Home.lockers")}
                </Typography>
                <UnderLine />
              </Stack>
            </CustomCard>
          </Card>
          <Card
            width={!md ? "264px" : "342px"}
            height={!md ? "142px" : "336px"}
            color="#7EA3C2"
          >
            <Typography
              variant="body2"
              fontWeight={400}
              color="white"
              width={300}
              sx={{
                fontSize: { xs: "11px", md: "18px" },
                mt: { xs: "90px", md: "200px" },
              }}
            >
              {t("Home.proudDesc3")}
            </Typography>
            <CustomCard
              width={!md ? "264px" : "342px"}
              height={!md ? "142px" : "336px"}
              bgUrl="/pudo.png"
            >
              <Stack sx={{ gap: { xs: 0, md: 1 } }}>
                <Typography
                  variant="h5"
                  color="white"
                  sx={{
                    fontSize: { xs: "18px", md: "23px" },
                    mt: { xs: "200px", md: "280px" },
                  }}
                >
                  {t("Home.pudo")}
                </Typography>
                <UnderLine />
              </Stack>
            </CustomCard>
          </Card>
        </Box>
      </Stack>
    </Stack>
  );
}

function CustomCard({ bgUrl, width, height, children }) {
  return (
    <Box
      sx={{
        width,
        height,
        borderRadius: "36px 16px 36px 16px",
        position: "absolute",
        right: 20,
        bottom: { xs: 100, sm: 110 },
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "flex-start",
        gap: "16px",
        padding: "40px 40px 56px 40px",
        backgroundImage: `linear-gradient(180deg, rgba(17,38,74,0.00) 0%, #11264A 100%), url("${bgUrl}")`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
      }}
    >
      {children}
    </Box>
  );
}

function UnderLine() {
  return (
    <Box bgcolor="green.100" width="120px" height="6px" borderRadius="4.5px" />
  );
}
