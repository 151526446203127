import { Box, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useBreakpoints } from "../../../app/hooks/useBreakpoints";

export default function Cards() {
  const [t] = useTranslation("global");
  const { sm, lg } = useBreakpoints();
  return (
    <>
      <Stack pt={11} pb={10} alignItems="center">
        <Box
          display="flex"
          gap={4}
          justifyContent="center"
          flexDirection={!lg && "column"}
        >
          <CustomBox width="388px" height="320px">
            <CustomBoxImg src={"/toDoor/Truck.png"} />
            <Box width="300px">
              <CustomTypo>{t("ToDoor.card1")}</CustomTypo>
            </Box>
          </CustomBox>

          <CustomBox width="388px" height="320px">
            <CustomBoxImg src={"/toDoor/DeliverMan.png"} />
            <Box width="250px">
              <CustomTypo>{t("ToDoor.card2")}</CustomTypo>
            </Box>
          </CustomBox>

          <CustomBox width="388px" height="320px">
            <CustomBoxImg src={"/toDoor/Email.png"} />
            <Box width="250px">
              <CustomTypo>{t("ToDoor.card3")}</CustomTypo>
            </Box>
          </CustomBox>
        </Box>

        <Box
          display="flex"
          pt={4}
          gap={4}
          justifyContent="center"
          flexDirection={!lg && "column"}
        >
          <CustomBox width={sm ? "598px" : "388px"} height="320px">
            <CustomBoxImg src={"/toDoor/Trolley.png"} />
            <Box width="350px">
              <CustomTypo>{t("ToDoor.card4")}</CustomTypo>
            </Box>
          </CustomBox>
          <CustomBox width={sm ? "598px" : "388px"} height="320px">
            <CustomBoxImg src={"/toDoor/Shop.png"} />
            <Stack>
              <Typography
                sx={{ color: "#808080", textAlign: "center", fontSize: "14px" }}
              >
                {t("ToDoor.onlySpain")}
              </Typography>
              <Box width="350px">
                <CustomTypo>{t("ToDoor.card5")}</CustomTypo>
              </Box>
            </Stack>
          </CustomBox>
        </Box>
      </Stack>

      <Stack pb={6} sx={{ backgroundColor: "#F7F7F7" }}>
        <CustomTypoBold pt={8}>{t("ToDoor.sustainable")}</CustomTypoBold>

        <Box
          display="flex"
          pt={sm ? 14 : 3}
          pb={2}
          sx={{ alignContent: "center", justifyContent: "center" }}
          flexDirection={!lg && "column"}
          alignItems="center"
        >
          <Stack pr={lg && 6} pt={lg && 2} alignItems="center">
            <Typography variant="h4" color="#0081ED" fontSize="72px">
              20%
            </Typography>
            <Box width="250px">
              <CustomTypoBold pt={4}>
                {t("ToDoor.sustainableDesc")}
              </CustomTypoBold>
            </Box>
          </Stack>

          {lg && <Box width="4px" height="144px" backgroundColor={"#0081ED"} />}
          {!lg && (
            <Box width="144px" height="4px" backgroundColor={"#0081ED"} />
          )}

          <Typography
            pl={lg && 4}
            pt={5}
            fontSize="1.25rem"
            color="#7B7B7B"
            variant="caption"
            fontWeight={100}
            width="100px"
            textAlign="center"
            mb={!lg && 3}
          >
            {t("ToDoor.methods")}
          </Typography>

          <Box
            pl={lg && 6}
            display="flex"
            gap={8}
            flexDirection={!sm && "column"}
            alignItems={!sm && "center"}
          >
            <Stack pr={sm && 2} alignItems="center">
              <CustomBoxCircular
                src={"/toDoor/Vehiculos electricos.svg"}
              ></CustomBoxCircular>
              <TypoUnderIcons width="150px">
                {t("ToDoor.vehicle")}
              </TypoUnderIcons>
            </Stack>

            <Stack alignItems="center">
              <CustomBoxCircular
                src={"/toDoor/Scooter.svg"}
              ></CustomBoxCircular>
              <TypoUnderIcons width="170px">
                {t("ToDoor.scooter")}
              </TypoUnderIcons>
            </Stack>

            <Stack alignItems="center">
              <CustomBoxCircular
                src={"/toDoor/Caminando.svg"}
              ></CustomBoxCircular>
              <TypoUnderIcons pt={4.5}>{t("ToDoor.walking")}</TypoUnderIcons>
            </Stack>
          </Box>
        </Box>
      </Stack>
    </>
  );
}

const CustomBox = ({ children, width, height }) => {
  return (
    <Box
      bgcolor="#CDE8FF"
      display="flex"
      flexDirection={"column"}
      justifyContent="center"
      alignItems="center"
      borderRadius={"36px 16px 36px 16px"}
      width={{ width }}
      height={{ height }}
    >
      {children}
    </Box>
  );
};

const CustomTypo = ({ children }) => {
  return (
    <Typography
      sx={{
        fontSize: "24px",
        color: "#000000",
        letterSpacing: "0",
        textAlign: "center",
        lineHeight: "33px",
        fontWeight: 700,
      }}
    >
      {children}
    </Typography>
  );
};

const CustomBoxImg = ({ src }) => {
  return (
    <Box
      component="img"
      src={src}
      alt="Imagen en Box"
      display={"flex"}
      sx={{
        width: "140px",
        height: "140px",
        objectFit: "cover",
      }}
    />
  );
};

const CustomTypoBold = ({ children, ...props }) => {
  return (
    <Typography
      variant="h4"
      fontWeight="bold"
      fontSize="2rem"
      textAlign={"center"}
      {...props}
    >
      {children}
    </Typography>
  );
};

const CustomBoxCircular = ({ src }) => {
  return (
    <Box
      component="img"
      src={src}
      sx={{
        width: "136px",
        height: "136px",
        borderRadius: "50%",
        backgroundColor: "white",
        padding: 4,
        objectFit: "cover",
      }}
    />
  );
};

const TypoUnderIcons = ({ children, ...props }) => {
  return (
    <Typography
      pt={3}
      textAlign={"center"}
      variant="caption"
      fontWeight={400}
      fontSize={"1.25rem"}
      {...props}
    >
      {children}
    </Typography>
  );
};
