import Banner from "../../components/Banner";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import Map from "./components/Map";
import Section1 from "./components/Section1";
import Section2 from "./components/Section2";
import UnderMap from "./components/UnderMap";
import ChatWithUs from "../../components/chatWithUs";
import Steps from "./components/Steps";
import ContactSection from "../../components/ContactSection";
import { useTranslation } from "react-i18next";

export default function PickUpPoint() {
  const [t] = useTranslation("global");
  const bannerProps = {
    title: t("PickUp.banner-title"),
    subtitle: t("PickUp.banner-desc"),
    imgURL: "/pickUpPoint/03banner.png",
  };

  return (
    <>
      <Header />
      <Banner bannerProps={bannerProps} />
      <Map />
      <UnderMap />
      <Section1 />
      <Section2 />
      <Steps />
      {/* <ContactSection isCustomer={false} /> */}
      <ChatWithUs />
      <Footer />
    </>
  );
}
