import { Box, Stack, Typography } from "@mui/material";
import TypoBold from "./TypoBold";
import { useTranslation } from "react-i18next";

export default function Map() {
  const [t] = useTranslation("global");

  return (
    <Stack
      sx={{
        height: {
          xs: "425px",
          sm: "550px",
        },
        pl: {
          xs: 1,
          sm: 4,
        },
      }}
    >
      <Box
        width="100%"
        sx={{
          height: {
            xs: "2vh",
            sm: "7vh",
          },
          background: "linear-gradient(to bottom, #E8F4FF, transparent)",
        }}
      />
      <Box
        display="flex"
        width="100%"
        sx={{
          height: "100%",
          position: "relative",
        }}
      >
        <Stack
          display="flex"
          sx={{
            width: "45%",
            height: "auto",
            zIndex: 3,
            pl: {
              xs: 0,
              sm: 8,
            },
          }}
        >
          <TypoBold>{t("PickUp.pudo")}</TypoBold>
          <TypoText fontWeight={500}>{t("PickUp.subtitle")}</TypoText>
          <TypoText fontWeight={300} pt={2}>
            {t("PickUp.subtitleDesc")}
          </TypoText>

          {/* <CustomButton /> */}
        </Stack>
        <Box
          sx={{
            position: "absolute",
            width: {
              xs: "25%",
              sm: "15%",
            },
            height: "100%",
            top: 0,
            left: {
              xs: "40%",
              sm: "47%",
            },
            background:
              "linear-gradient(to right, rgba(255, 255, 255, 0.8), transparent)",
            zIndex: 2,
          }}
        />
        <Box
          sx={{
            zIndex: 1,
            objectFit: "contain",
            position: "absolute",
            top: {
              xs: "30%",
              sm: 0,
            },
            left: {
              xs: "5%",
              sm: "37%",
            },
            width: {
              xs: "100%",
              sm: "40%",
            },
            height: {
              sm: "100%",
            },
          }}
          component="img"
          src="/pickUpPoint/map.png"
        />
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          sx={{
            position: "absolute",
            left: {
              xs: "46%",
              sm: "50%",
            },
            width: {
              xs: "50%",
              sm: "25%",
            },
            height: "100%",
            zIndex: 2,
          }}
        >
          <Stack>
            <TypoBold
              color="#0081ED"
              sx={{ fontSize: { xs: "30px", sm: "4em" } }}
              fontWeight={500}
            >
              4000+
            </TypoBold>
            <TypoBold
              color="#074478"
              sx={{
                fontSize: { xs: "12px", sm: "1.2em" },
                pt: { xs: 0, sm: 2 },
              }}
            >
              {t("Home.pudo")}
            </TypoBold>
          </Stack>
        </Box>
      </Box>
    </Stack>
  );
}

// const CustomButton = ({children, ...props}) => {
//   const handleClick = () => {
//     alert('TO DO Route');
//   };

//   return (
//     <Box
//     sx={{
//       marginTop: {
//         xs: 2,
//         sm: 3
//       },
//       width: {
//         xs: '95%',
//         sm: '65%'
//       },
//       display: 'flex',
//       alignItems: 'center',
//       justifyContent: 'center',
//       cursor: 'pointer',
//       borderRadius: '45px',
//       position: 'relative',
//       overflow: 'hidden',
//       transition: 'all 0.2s ease',
//       '&:hover': {
//         opacity: 0.8,
//         transform: 'scale(1.02)',
//       }
//     }}
//       onClick={handleClick}
//       {...props}
//     >
//       <Box
//         component='img'
//         src='/pickUpPoint/blue button hoverL.png'
//         sx={{
//           width: '100%',
//           height: '100%',
//           objectFit: 'contain',
//         }}
//       />
//       <Typography
//         variant='button'
//         sx={{
//           position: 'absolute',
//           fontWeight: 'bold',
//           color: 'white',
//           zIndex: 1,
//           fontSize: {
//             xs: '7px',
//             sm: '80%'
//           }
//         }}
//       >
//         Ubica tu Punto más cercano
//       </Typography>
//     </Box>
//   )
// }

const TypoText = ({ children, ...props }) => {
  return (
    <Typography
      textAlign="left"
      lineHeight="40px"
      sx={{
        pt: {
          xs: 1,
          sm: 4,
        },
        fontSize: {
          xs: "12px",
          sm: "1.5em",
        },
      }}
      {...props}
    >
      {children}
    </Typography>
  );
};
