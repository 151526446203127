import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import TypoBold from "./TypoBold";
import { useTranslation } from "react-i18next";

export default function UnderMap() {
  const [t] = useTranslation("global");

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexWrap="wrap"
      sx={{
        flexDirection: {
          xs: "column-reverse",
          sm: "row",
        },
        pb: 10,
      }}
    >
      <Box
        position="relative"
        flexDirection="column"
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{
          width: {
            xs: "100%",
            sm: "50%",
          },
          height: {
            xs: "200px",
            sm: "320px",
          },
          pt: {
            xs: "15%",
            sm: 0,
          },
        }}
      >
        <TypoBold
          zIndex={3}
          color="#0081ED"
          sx={{ fontSize: { xs: "30px", sm: "4em" } }}
          fontWeight={500}
        >
          500+
        </TypoBold>
        <TypoBold
          zIndex={3}
          color="#074478"
          sx={{ fontSize: { xs: "12px", sm: "2em" }, pt: { xs: 0, sm: 1 } }}
        >
          Lockers
        </TypoBold>
        <Box
          zIndex={1}
          position="absolute"
          height="auto"
          component="img"
          src="/pickUpPoint/video.png"
          sx={{
            width: {
              xs: "100%",
              sm: "70%",
            },
          }}
        />
      </Box>

      <Stack
        sx={{
          width: {
            xs: "100%",
            sm: "50%",
          },
          height: "100%",
          pl: {
            xs: 1,
            sm: 0,
          },
        }}
      >
        <TypoBold pb={1}>Lockers</TypoBold>
        <TypoText pb={2} fontWeight={700}>
          {t("PickUp.subtitle2")}
        </TypoText>
        <TypoText fontWeight={300}>{t("PickUp.subtitleDesc2")}</TypoText>
      </Stack>
    </Box>
  );
}

const TypoText = ({ children, ...props }) => {
  return (
    <Typography
      textAlign="left"
      lineHeight="30px"
      sx={{
        fontSize: {
          xs: "12px",
          sm: "1.2em",
        },
      }}
      {...props}
    >
      {children}
    </Typography>
  );
};
