import { Box } from "@mui/material";
import Tracking from "../../../../Home/Tracking";
import Logo from "../../../../Home/images/Logo.svg";
import { useBreakpoints } from "../../../../app/hooks/useBreakpoints";

function SearchTracking() {
  const { md, lg, sm, xs, xl } = useBreakpoints();
  return (
    <>
      <Box
        mt={10}
        sx={{
          backgroundImage: `url(/background.png)`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          height: "30vh",
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            ml: { xl: "25rem", lg: "22rem", md: "5rem", xs: "2rem" },
          }}
        >
          <Box
            component="img"
            alt="logo"
            src={Logo}
            width={md ? "100px" : "50px"}
          />
        </Box>
        <Tracking
          isCustomer
          isCustomerXs={xs}
          inputStyle={{
            marginLeft: xl
              ? "36rem"
              : lg
              ? "33rem"
              : md
              ? "20rem"
              : sm
              ? "8rem"
              : xs
              ? "7rem"
              : "",
            height: lg
              ? "100px"
              : md
              ? "100px"
              : sm
              ? "50px"
              : xs
              ? "30px"
              : "",
            fontSize: md ? "60px" : "30px",
          }}
          iconStyle={{
            height: lg
              ? "100px"
              : md
              ? "100px"
              : sm
              ? "50px"
              : xs
              ? "30px"
              : "",
            width: "100px",
          }}
        />
      </Box>
    </>
  );
}

export default SearchTracking;
