import { Box, Button, Link, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { Phone } from "@mui/icons-material";
import EmailIcon from "@mui/icons-material/Email";

export default function ContactInfo() {
  const [t, i18n] = useTranslation("global");
  const isEng = i18n.language === "en";
  const isPt = i18n.language === "pt";
  const contactItems = [
    {
      icon: <EmailIcon />,
      title: "Customer.email",
      link: "mailto:{t('Customer.email')}",
    },
    {
      icon: <Phone />,
      title: "Customer.phone",
    },
    {
      icon: <AccessTimeIcon />,
      title: "Customer.time",
      link: "",
    },
  ];

  return (
    <Box sx={{ position: "relative" }}>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        sx={{
          p: "30px",
          width: "100%",
          height: "auto",
          backgroundImage: "url(/home/contactInfoHome.png)",
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          gap: { xs: 0, sm: 7, lg: 10 },
          flexDirection: { xs: "column", sm: "row" },
        }}
      >
        <Stack
          gap="42px"
          sx={{
            marginTop: { xs: 10, sm: 0 },
            width: { xs: "100%", sm: "auto" },
          }}
        >
          <Stack gap={2.25}>
            <Typography variant="h2" fontSize={40} color="white">
              {t("Home.help")}
            </Typography>
            <Typography
              fontSize={24}
              color="white"
              sx={{
                opacity: "0.5",
              }}
            >
              {t("Home.helpDesc")}
            </Typography>
          </Stack>
          <Stack gap={3}>
            {contactItems.map((item) => (
              <Box display="flex" gap={1}>
                <Box width={24} height={24} color="white">
                  {item.icon}
                </Box>
                <Typography
                  variant="subtitle1"
                  color="white"
                  sx={{
                    fontSize: { xs: "14px", sm: "20px" },
                    opacity: "0.4",
                  }}
                >
                  {t(item.title)}
                </Typography>
              </Box>
            ))}
          </Stack>
        </Stack>
        {/* comment out from here */}
        {/* <Box
          bgcolor="white"
          sx={{
            marginTop: { xs: 2, sm: 0 },
            height: { xs: "2.71px", sm: 210 },
            width: { xs: 210, sm: "2.71px" },
          }}
        />
        <Stack
          gap={2.25}
          sx={{
            marginTop: { xs: 2, sm: 3 },
            width: { xs: "100%", sm: "auto" },
          }}
        >
          <Typography variant="h2" fontSize={40} color="white">
            {t("Home.cooperation")}
          </Typography>
          <Typography
            fontSize={24}
            color="white"
            sx={{
              opacity: "0.5",
            }}
          >
            {t("Home.cooperationDesc")}
          </Typography>
          <Button
            disableRipple
            disableFocusRipple
            sx={{
              "&:hover": {
                background: "none",
              },
              alignSelf: "flex-start",
            }}
          >
            <Box
              component="img"
              src="/greenButtonNormal.png"
              sx={{ width: { xs: "234px", sm: 347 } }}
            />
            <Link href="/contactForm?isCustomer=true">
              <Typography
                textAlign="center"
                position="absolute"
                color="white"
                fontWeight="bold"
                sx={{
                  fontSize: {
                    xs: isPt ? "10px" : "16px",
                    sm: isPt ? "15px" : "24px",
                  },
                  textDecoration: "none",
                  left: {
                    xs: isEng || isPt ? "32px" : "52px",
                    sm: isEng || isPt ? "50px" : "75px",
                  },
                  bottom: { xs: "24px", sm: "35px" },
                }}
              >
                {t("Home.contact")}
              </Typography>
            </Link>
          </Button>
        </Stack> */}
      </Box>
    </Box>
  );
}
