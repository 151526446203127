import { Box, Stack, Typography } from "@mui/material";
import Card from "../../../components/Card";
import TimeIcon from "./TimeIcon";
import ShippingIcon from "./ShippingIcon";
import ShipperIcon from "./ShipperIcon";
import RecycleIcon from "./RecycleIcon";
import { useTranslation } from "react-i18next";
import { useBreakpoints } from "../../../app/hooks/useBreakpoints";

export default function Section1() {
  const [t] = useTranslation("global");
  const { md } = useBreakpoints();

  return (
    <Stack
      bgcolor="#DEF0FF"
      sx={{
        background:
          "linear-gradient(180deg, #DEF0FF 0%, rgba(232,244,255,0.00) 100%);",
        pt: { xs: 4, md: 0 },
      }}
    >
      <Stack
        padding="108px"
        alignItems="center"
        sx={{ padding: { xs: "20px", md: "108px" }, gap: { xs: 1, md: 6 } }}
      >
        <Typography variant="h1" sx={{ fontSize: { xs: "30px", md: "48px" } }}>
          {t("Home.value")}
        </Typography>
        <Box
          display="flex"
          sx={{
            flexDirection: { xs: "column", lg: "row" },
            gap: { xs: 1, md: 6 },
          }}
        >
          <Card
            width={{ xs: "280px", sm: "592px" }}
            height="208px"
            color="#445D73"
          >
            <Typography variant="h4" fontWeight={700} color="white">
              {t("Home.advantagePrice")}
            </Typography>
            <Typography variant="body1" fontSize="22px" color="white">
              {t("Home.advantagePriceDesc")}
            </Typography>
            <Typography variant="h6" color="#CCF495" fontWeight={700}>
              {t("Home.advantageTime")}
            </Typography>
            <Box
              sx={{
                position: "absolute",
                top: { xs: 140, md: 90 },
                right: { xs: 13, md: 20 },
              }}
            >
              {!md && <TimeIcon width="45px" height="45px" />}
              {md && <TimeIcon />}
            </Box>
          </Card>
          <Card
            width={{ xs: "280px", sm: "592px" }}
            height="208px"
            color="#198DEFBF"
          >
            <Typography variant="h4" fontWeight={700} color="white">
              {t("Home.solutions")}
            </Typography>
            <Typography variant="body1" fontSize="22px" color="white">
              {t("Home.solutionsDesc")}
            </Typography>
            <Box
              sx={{
                position: "absolute",
                top: { xs: 140, md: 90 },
                right: { xs: 15, md: 20 },
              }}
            >
              {!md && <ShipperIcon width="45px" height="45px" />}
              {md && <ShipperIcon />}
            </Box>
          </Card>
        </Box>
        <Box
          display="flex"
          sx={{
            flexDirection: { xs: "column", lg: "row" },
            gap: { xs: 1, md: 6 },
          }}
        >
          <Card
            width={{ xs: "280px", sm: "592px" }}
            height={{ xs: "248px", sm: "208px" }}
            color="green.200"
          >
            <Typography variant="h4" fontWeight={700} color="#001E37">
              {t("Home.toDoor")}
            </Typography>
            <Box sx={{ width: { xs: "100%", md: "400px" } }}>
              <Typography variant="body1" fontSize="22px" color="white">
                {t("Home.toDoorDesc")}
              </Typography>
              <Typography variant="body1" fontSize="22px" color="white">
                {t("Home.toDoorDescPt")}
              </Typography>
            </Box>
            <Box
              sx={{
                position: "absolute",
                top: { xs: 145, md: 90 },
                right: { xs: 15, md: 20 },
              }}
            >
              {!md && <ShippingIcon width="45px" height="45px" />}
              {md && <ShippingIcon />}
            </Box>
          </Card>
          <Card
            width={{ xs: "280px", sm: "592px" }}
            height="208px"
            color="#1B5FC0"
          >
            <Typography variant="h4" fontWeight={700} color="#001E37">
              {t("Home.easyDelivery")}
            </Typography>
            <Box sx={{ width: { xs: "100%", md: "420px" } }}>
              <Typography variant="body1" fontSize="22px" color="white">
                {t("Home.easyDeliveryDesc")}
              </Typography>
            </Box>
            <Box
              sx={{
                position: "absolute",
                top: { xs: 140, md: 90 },
                right: { xs: 15, md: 20 },
              }}
            >
              {!md && <RecycleIcon width="45px" height="45px" />}
              {md && <RecycleIcon />}
            </Box>
          </Card>
        </Box>
      </Stack>
    </Stack>
  );
}
