import CustomHeader from "./components/CustomHeader";
import Section1 from "./components/Section1";
import Footer from "../../components/Footer";
import Section2 from "./components/Section2";
import ContactInfo from "./components/ContactInfo";
import ChatWithUs from "../../components/chatWithUs";

export default function index() {
  return (
    <>
      <CustomHeader />
      <Section1 />
      <Section2 />
      <ContactInfo />
      <ChatWithUs />
      <Footer />
    </>
  );
}
