import { Box, Stack, Typography } from "@mui/material";
import TypoBold from "./TypoBold";
import { useTranslation } from "react-i18next";

export default function Section2() {
  const [t] = useTranslation("global");
  return (
    <Stack>
      <TypoBold
        pt={6}
        textAlign="center"
        sx={{
          fontSize: {
            xs: "20px",
            sm: "42px",
          },
        }}
      >
        {t("PickUp.benefitTitle2")}
      </TypoBold>

      <Box
        pb={4}
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexWrap="wrap"
        sx={{
          pt: {
            xs: 0,
            sm: 4,
          },
          gap: {
            xs: "30px",
            sm: "30px",
          },
          margin: 4,
        }}
      >
        <CustomBox>
          <CustomImg src="/pickUpPoint/Cercania.png" />
          <Typo fontSize="20px">{t("PickUp.card2-1")}</Typo>
        </CustomBox>

        <CustomBox>
          <CustomImg src="/pickUpPoint/Flexibilidad horaria.png" />
          <Typo fontSize="20px"> {t("PickUp.card2-2")}</Typo>
        </CustomBox>

        <CustomBox>
          <CustomImg src="/pickUpPoint/Seguridad.png" />
          <Typo fontSize="20px">{t("PickUp.card2-3")}</Typo>
        </CustomBox>

        <CustomBox flex-wrap="wrap">
          <CustomImg src="/pickUpPoint/Entrega sostenible.png" />
          <Typo textAlign="center" fontSize="20px">
            {t("PickUp.card2-4")}
          </Typo>
        </CustomBox>
      </Box>
    </Stack>
  );
}

const Typo = ({ children, ...props }) => {
  return (
    <Typography fontSize="20px" fontWeight={500} {...props}>
      {children}
    </Typography>
  );
};

const CustomBox = ({ children, ...props }) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      sx={{
        // border: 'solid green 3px',
        backgroundColor: "#F2F9FF",
        width: {
          xs: "70%",
          sm: "20%",
        },
        height: {
          xs: "146px",
          sm: "346px",
        },
        borderRadius: "15px",
        pb: {
          xs: 2,
          sm: 0,
        },
      }}
      {...props}
    >
      {children}
    </Box>
  );
};

const CustomImg = ({ src }) => {
  return (
    <Box
      component="img"
      src={src}
      sx={{
        width: {
          xs: "35%",
          sm: "55%",
        },
        objectFit: "contain",
      }}
    />
  );
};
