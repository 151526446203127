import { useTranslation } from "react-i18next";
import Banner from "../../components/Banner";
import ChatWithUs from "../../components/chatWithUs";
import ContactSection from "../../components/ContactSection";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import Cards from "./components/Cards";

export default function ToDoor() {
  const [t] = useTranslation("global");
  const bannerProps = {
    title: t("ToDoor.toDoor"),
    subtitle: t("ToDoor.bannerDesc"),
    imgURL: "/domicilioBanner.png",
  };

  return (
    <>
      <Header />
      <Banner bannerProps={bannerProps} />
      <Cards />
      {/* <ContactSection isCustomer /> */}
      <Footer />
      <ChatWithUs />
    </>
  );
}
