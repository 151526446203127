import { Box, Typography } from "@mui/material";
import TypoBold from "./TypoBold";
import { useTranslation } from "react-i18next";

export default function Steps() {
  const [t] = useTranslation("global");
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      sx={{
        backgroundImage:
          "linear-gradient(to right, #113B7C 20%, #0E479C 50%,#0E479C 70%,rgb(36, 91, 172) 85%, rgb(51, 127, 220) 100%)",
        width: "100%",
        height: "460px",
      }}
    >
      <TypoBold color="white">{t("PickUp.lockerTitle")}</TypoBold>
      <TypoText color="rgba(255, 255, 255, 0.8)">
        {t("PickUp.lockerQues")}
      </TypoText>
      <Box
        display="flex"
        alignItems="center"
        sx={{
          flexDirection: { xs: "column", sm: "row" },
          width: { xs: "100%", sm: "80%" },
          height: { xs: "100%", sm: "120px" },
          pt: { xs: 2, sm: 0 },
          pb: { xs: 2, sm: 0 },
          gap: { xs: 2, sm: 0 },
        }}
      >
        <StepBox
          number={1}
          text={t("PickUp.card1")}
          clipPath="polygon(0% 0%, 85% 0%, 100% 50%, 85% 100%, 0% 100%)"
          paddingLeft={7}
        />
        <StepBox
          number={2}
          text={t("PickUp.card2")}
          clipPath="polygon(0% 0%, 85% 0%, 100% 50%, 85% 100%, 0% 100%, 15% 50%)"
          paddingLeft={10}
        />
        <StepBox
          number={3}
          text={t("PickUp.card3")}
          clipPath="polygon(0% 0%, 100% 0%, 100% 100%, 0 100%, 15% 50%)"
          paddingLeft={6}
        />
      </Box>
    </Box>
  );
}

const TypoText = ({ children, ...props }) => (
  <Typography
    textAlign="left"
    lineHeight="30px"
    sx={{
      pt: { sm: 4 },
      pb: { sm: 2 },
      fontSize: { xs: "12px", sm: "1em" },
    }}
    {...props}
  >
    {children}
  </Typography>
);

const TypoText2 = ({ children, ...props }) => (
  <Typography
    sx={{
      fontSize: { xs: "12px", sm: "1em" },
    }}
    fontWeight={500}
    {...props}
  >
    {children}
  </Typography>
);

const StepBox = ({
  number,
  text,
  clipPath,
  paddingLeft = 1,
  paddingRight = 2,
}) => (
  <Box
    display="flex"
    justifyContent="center"
    alignItems="center"
    gap={1}
    sx={{
      width: { xs: "70%", sm: "33%" },
      height: { xs: "60%", sm: "100%" },
      pl: { xs: paddingLeft / 2, sm: paddingLeft },
      pr: { xs: paddingRight, sm: 6 },
      backgroundColor: "white",
      clipPath: clipPath,
    }}
  >
    <TypoText2 color="#0081ED" sx={{ fontSize: { xs: "30px", sm: "48px" } }}>
      {number}.
    </TypoText2>
    <TypoText2 color="black">{text}</TypoText2>
  </Box>
);
