import React from "react";
import { Row, Col } from "antd";
import Logo from "./images/logo.jpg";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import analiticas from "./images/analiticas.png";
import necesarias from "./images/necesarias.png";
import marketing from "./images/marketing.png";
import Footer from "../components/Footer";

export default function Cookies() {
  const [t, i18n] = useTranslation("global");
  return (
    <div>
      <div className="container">
        <Row>
          <Col lg={3} sm={24} xs={24} span={8}></Col>
          <Col lg={18} sm={24} xs={24}>
            <Link to="/">
              <img
                alt="Ecoscooting Delivery"
                src={Logo}
                style={{ width: "30%", marginBottom: "50px" }}
              />
            </Link>
            <div className="politicas">
              <h1>POLITICA DE COOKIES</h1>
              <p>
                Nuestra página Web utiliza cookies propias y de terceros. Una
                cookie es un fichero que se descarga en su ordenador al acceder
                a determinadas páginas web, entre otras finalidades, asegurar el
                correcto funcionamiento de la página, permitir al Usuario un
                acceso más rápido a los servicios seleccionados, almacenar y
                recuperar información sobre los hábitos de navegación de un
                usuario o de su equipo e incluso, dependiendo de la información
                que contengan y de la forma en que utilice su equipo, se pueden
                utilizar para reconocer al usuario. Las cookies se asocian
                únicamente a un usuario anónimo y su ordenador o dispositivo y
                no proporcionan referencias que permitan conocer sus datos
                personales, salvo permiso expreso de aquél. El usuario puede, en
                todo momento, aceptar o rechazar las cookies instaladas que no
                sean estrictamente necesarias para el correcto funcionamiento de
                la web y el acceso al Usuario a sus servicios, a través del
                panel de ajuste de cookies proporcionado en nuestra web.
                Asimismo, podrá configurar su navegador en todo momento sin que
                ello perjudique la posibilidad del Usuario de acceder a los
                contenidos. Sin embargo, le informamos de que el rechazo de las
                cookies puede disminuir el buen funcionamiento de la web.
              </p>
              <h1>COOKIES AFECTADAS POR LA NORMATIVA Y COOKIES EXENTAS</h1>
              Según la directiva de la UE, las cookies que requieren el
              consentimiento informado por parte del usuario son las cookies de
              analítica, las de publicidad y afiliación, quedando exceptuadas
              las de carácter técnico y las necesarias para el funcionamiento
              del sitio web o la prestación de servicios expresamente demandados
              por el usuario.
              <h1>¿Qué tipos de cookies utilizamos?</h1>
              <p>
                Distinguimos entre cookies en función de su origen: cookies que
                colocamos y gestionamos nosotros mismos (cookies de origen) y es
                posible que de vez en cuando terceros coloquen cookies en
                nuestros sitios web (cookies de terceros). Las cookies de
                terceros son cookies colocadas por un sitio web distinto del que
                está visitando. Estas cookies permiten el envío de determinados
                datos a terceros, basándose en su visita a nuestro sitio web.
                Las cookies de terceros son gestionadas en su totalidad por
                terceros, y no tenemos ningún control sobre la forma en que
                estos terceros gestionan las cookies en cuestión o utilizan los
                datos recogidos de esta forma. Por lo tanto, le aconsejamos que
                consulte la política de privacidad y la política de cookies de
                los terceros en cuestión si desea obtener más información al
                respecto. En función de la vida útil de las cookies, también
                distinguimos entre cookies de sesión y persistentes. Las cookies
                de sesión se almacenan temporalmente en su navegador. En cuanto
                se cierra el navegador, estas cookies se borran automáticamente.
                Las cookies persistentes permanecen en su ordenador o
                dispositivo móvil, incluso después de haber cerrado el
                navegador. Estas cookies permanecen en su dispositivo hasta que
                se alcanza su fecha de caducidad, hasta que se instala una nueva
                versión de la cookie o hasta que usted mismo las elimina a
                través de la configuración de su navegador. Las cookies
                colocadas por nuestro sitio web pueden dividirse en diferentes
                categorías basadas en su funcionalidad, como cookies funcionales
                y estrictamente necesarias, cookies analíticas y cookies de
                marketing:
              </p>
              <h1>Necesario</h1>
              <p>
                Las cookies necesarias ayudan a hacer una página web utilizable
                activando funciones básicas como la navegación en la página y el
                acceso a áreas seguras de la página web. La página web no puede
                funcionar adecuadamente sin estas cookies.
              </p>
              <img src={necesarias} alt="Cookies Necesarias" /> <br />
              <h1>Cookies analíticas</h1>
              <p>
                Las cookies analíticas recogen datos agregados sobre el uso de
                nuestro sitio web, como el número de visitantes, qué páginas web
                se visitan con más frecuencia y cuánto tiempo pasan los usuarios
                en una página web concreta. Esta información nos permite
                realizar análisis estadísticos que nos ayudan a mejorar la
                navegación y el contenido de nuestro sitio web y a optimizar la
                experiencia del usuario. Es posible que para ello utilicemos
                cookies de terceros. El uso de estas cookies está sujeto a su
                consentimiento previo. Por lo tanto, puede negarse a permitir
                que estas cookies se coloquen en su dispositivo configurando sus
                preferencias de cookies en la configuración de cookies.
              </p>
              <img src={analiticas} alt="Cookies analiticas" /> <br />
              <h1>Marketing</h1>
              <p>
                Las cookies de marketing se utilizan para rastrear a los
                visitantes en las páginas web. La intención es mostrar anuncios
                relevantes y atractivos para el usuario individual, y por lo
                tanto, más valiosos para los editores y terceros anunciantes.
              </p>
              <img src={marketing} alt="Cookies marketing" /> <br />
              <h1>REVOCACIÓN</h1>
              <p>
                En todo momento podrá acceder a la configuración de su navegador
                aceptando o rechazando todas las cookies, o bien seleccionar
                aquéllas cuya instalación admite y cuáles no, siguiendo uno de
                los siguientes procedimientos, que depende del navegador que
                utilice: Google Chrome (en el Menú Herramientas): Configuración
                > Mostrar opciones avanzadas > Privacidad (Configuración de
                contenido) > Cookies:{" "}
                <a href="https://support.google.com/chrome/answer/95647?co=GENIE.Platform=Desktopandhl=es">
                  https://support.google.com/chrome/answer/95647?co=GENIE.Platform=Desktopandhl=es
                </a>
                Microsoft Internet Explorer (en el Menú Herramientas):  Opciones
                de Internet > Privacidad > Avanzada:{" "}
                <a href="https://support.microsoft.com/es-es/help/17442/windows-internet-explorer-delete-manage-cookies">
                  https://support.microsoft.com/es-es/help/17442/windows-internet-explorer-delete-manage-cookies
                </a>
                Firefox: Opciones > Privacidad > Cookies:{" "}
                <a href="https://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-sitios-web-rastrear-preferencias">
                  https://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-sitios-web-rastrear-preferencias
                </a>{" "}
                  Safari, iPad y iPhone: Preferencias > Privacidad:{" "}
                <a href="https://support.apple.com/kb/ph21411?locale=es_ES">
                  https://support.apple.com/kb/ph21411?locale=es_ES
                </a>
                Opera: Configuración > Opciones > Avanzado > Cookies:{" "}
                <a href="http://help.opera.com/Windows/12.00/es-ES/cookies.html">
                  http://help.opera.com/Windows/12.00/es-ES/cookies.html
                </a>
                Estos navegadores están sometidos a actualizaciones o
                modificaciones, por lo que no podemos garantizar que se ajusten
                completamente a la versión de su navegador. También puede ser
                que utilice otro navegador no contemplado en estos enlaces como
                Konqueror, Arora, Flock, etc. Para evitar estos desajustes,
                puede acceder directamente desde las opciones de su navegador,
                generalmente en el menú de 'Opciones' en la sección de
                'Privacidad'. (Por favor, consulte la ayuda de su navegador para
                más información). 
              </p>
              <h1>DESACTIVACIÓN/ACTIVACIÓN Y ELIMINACIÓN DE COOKIES</h1>
              <p>
                Para restringir o bloquear las cookies, se hace a través de la
                configuración del navegador. Si no desea que los sitios web
                pongan ninguna cookie en su equipo, puede adaptar la
                configuración del navegador de modo que se le notifique antes de
                que se coloque ninguna cookie. De igual modo, puede adaptar la
                configuración de forma que el navegador rechace todas las
                cookies, o únicamente las cookies de terceros. También puede
                eliminar cualquiera de las cookies que ya se encuentren en el
                equipo. Tenga en cuenta que tendrá que adaptar por separado la
                configuración de cada navegador y equipo que utilice. Tenga en
                cuenta que si no desea recibir cookies, ya no podremos
                garantizar que nuestro sitio web funcione debidamente. Puede que
                algunas funciones del sitio se pierdan y es posible que ya no
                pueda ver ciertos sitios web. Además, rechazar las cookies no
                significa que ya no vaya a ver anuncios publicitarios.
                Simplemente los anuncios no se ajustarán a sus intereses y se
                repetirán con más frecuencia. Cada navegador posee un método
                distinto para adaptar la configuración. Si fuera necesario,
                consulte la función de ayuda del navegador para establecer la
                configuración correcta. Para desactivar las cookies en el
                teléfono móvil, consulte el manual del dispositivo para obtener
                más información. Puede obtener más información sobre las cookies
                en Internet,http://www.aboutcookies.org/.Teniendo en cuenta la
                forma en la que funciona Internet y los sitios web, no siempre
                contamos con información de las cookies que colocan terceras
                partes a través de nuestro sitio web. Esto se aplica
                especialmente a casos en los que nuestra página web contiene lo
                que se denominan elementos integrados: textos, documentos,
                imágenes o breves películas que se almacenan en otra parte, pero
                se muestran en nuestro sitio web o a través del mismo. Por
                consiguiente, en caso de que se encuentre con este tipo de
                cookies en este sitio web y no estén enumeradas en la lista
                anterior, le rogamos que nos lo comunique. O bien póngase en
                contacto directamente con el tercero para pedirle información
                sobre las cookies que coloca, la finalidad y la duración de la
                cookie, y cómo ha garantizado su privacidad.
              </p>
              <h1>CAMBIOS</h1>
              <p>
                Nuestro uso de cookies y los nombres, números y finalidades de
                las cookies mencionadas pueden cambiar con el tiempo. Por lo
                tanto, actualizaremos periódicamente esta política de cookies
                para reflejar dichos cambios. Esta política de cookies se
                modificó por última vez en julio de 2023. PREGUNTAS Y/O
                RECLAMACIONES Si tiene alguna pregunta u observación sobre
                nuestro uso de las cookies, no dude en ponerse en contacto con
                nosotros a través de la siguiente dirección de correo
                electrónico: privacy@ecoscooting.com. Si no respondemos a su
                solicitud o si nuestra respuesta no satisface sus expectativas,
                también tiene derecho a presentar una reclamación ante la
                Agencia Española de Protección de Datos
                (AEPD)(https://www.aepd.es/es). Puede ponerse en contacto con la
                Agencia Española de Protección de Datos por correo electrónico
                (contact@apd-gba.be) o por teléfono （- Tel: 91 266 35 17）{" "}
              </p>
              <h2>
                INFORMACIÓN RELACIONADA CON EL TRATAMIENTO DE DATOS
                PERSONALES(ART.13 RGPD)
              </h2>
              <a href="http://ecoscooting.com/politica-privacidad">
                Política de Privacidad.
              </a>
            </div>
          </Col>
          <Col lg={3} sm={24} xs={24} span={8}></Col>
        </Row>
      </div>
      <Footer />
    </div>
  );
}
