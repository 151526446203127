import { Box, Stack, Typography } from "@mui/material";
import TypoBold from "./TypoBold";
import { useTranslation } from "react-i18next";

export default function Section1() {
  const [t] = useTranslation("global");
  return (
    <>
      <Stack
        sx={{
          height: "100%",
          width: "auto",
          backgroundColor: "#F7F7F7",
          pl: {
            xs: 4,
            sm: 12,
          },
          pr: {
            xs: 1,
            sm: 12,
          },
          pb: 4,
        }}
      >
        <TypoBold
          sx={{
            pt: 6,
            pb: 1,
            pr: {
              xs: 0,
              sm: 5,
            },
            fontSize: {
              xs: "18px",
              sm: "40px",
            },
          }}
          textAlign="center"
        >
          {t("PickUp.benefitTitle1")}
        </TypoBold>

        <Box
          width="100%"
          pb={4}
          sx={{
            pt: 1,
            gap: {
              xs: 0,
              sm: 2,
            },
            width: {
              xs: "90%",
              sm: "100%",
            },
          }}
          display="flex"
          flexWrap="wrap"
        >
          <ImgContainer src="/pickUpPoint/Ingresos%20adicionales.png">
            <ImgBox src="/pickUpPoint/Ingresos.png" />
            <CustomTypo>{t("PickUp.card1-1")}</CustomTypo>
          </ImgContainer>

          <ImgContainer src="/pickUpPoint/Aumenta%20el%20trafico%20de%20clientes.png">
            <ImgBox src="/pickUpPoint/Aumenta el trafico.png" />
            <CustomTypo
              sx={{
                width: { xs: "260px", sm: "300px" },
              }}
            >
              {t("PickUp.card1-2")}
            </CustomTypo>
          </ImgContainer>

          <ImgContainer src="/pickUpPoint/Incrementa%20tus%20ventas.png">
            <ImgBox src="/pickUpPoint/Incrementa.png" />
            <CustomTypo>{t("PickUp.card1-3")}</CustomTypo>
          </ImgContainer>
        </Box>
      </Stack>
    </>
  );
}

const ImgContainer = ({ children, src }) => {
  return (
    <Box
      display="flex"
      flexWrap="wrap"
      width="100%"
      position="relative"
      justifyContent="center"
      alignItems="center"
      sx={{
        flex: "1 1 30%",
        backgroundImage: `url(${src})`,
        backgroundSize: "contain",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        pt: {
          xs: 10,
          sm: 20,
        },
        pb: {
          xs: 0,
          sm: 4,
        },
        minWidth: "30vh",
        minHeight: {
          xs: "20vh",
          sm: "40vh",
        },
        maxHeight: "100vh",
        overflow: "auto",
      }}
    >
      {children}
    </Box>
  );
};

const ImgBox = ({ src, ...props }) => {
  return (
    <Box
      width="100%"
      height="100%"
      component="img"
      src={src}
      zIndex={2}
      position="absolute"
      sx={{
        objectFit: "contain",
        top: {
          xs: "-10px",
          sm: "-30px",
        },
        left: "35%",
        width: "28%",
      }}
      {...props}
    />
  );
};

const CustomTypo = ({ children, sx }) => {
  return (
    <Typography
      textAlign="center"
      justifySelf="center"
      fontWeight={700}
      pt={1}
      zIndex={2}
      sx={{
        fontSize: {
          xs: "14px",
          sm: "22px",
        },
        ...sx,
      }}
    >
      {children}
    </Typography>
  );
};
