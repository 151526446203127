import React from "react";
import { Row, Col } from "antd";
import Logo from "./images/logo.jpg";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Footer from "../components/Footer";

export default function Aviso() {
  const [t, i18n] = useTranslation("global");
  return (
    <div>
      <div className="container">
        <Row>
          <Col lg={3} sm={24} xs={24} span={8}></Col>

          <Col lg={18} sm={24} xs={24}>
            <Link to="/">
              <img
                alt="Ecoscooting Delivery"
                src={Logo}
                style={{ width: "30%", marginBottom: "50px" }}
              />
            </Link>
            <div className="politicas">
              <h1>{t("Aviso-legal.legal-title1")}</h1>
              <p>{t("Aviso-legal.legal-text1")}</p>
              <br />
              <p>{t("Aviso-legal.legal-text2")}</p>
              <br />
              <h2>{t("Aviso-legal.legal-title2")}</h2> <br />
              <p>
                {t("Aviso-legal.legal-text3")}
                <br />
                {t("Aviso-legal.legal-text3")}
                <br />
                {t("Aviso-legal.legal-text4")}
                <br />
                {t("Aviso-legal.legal-text5")}
                <br />
                {t("Aviso-legal.legal-text6")}
              </p>
              <br />
              <h2>{t("Aviso-legal.legal-title3")}</h2> <br />
              <p>{t("Aviso-legal.legal-text7")}</p>
              <br />
              <h2>{t("Aviso-legal.legal-title4")}</h2>
              <br />
              <p>
                {t("Aviso-legal.legal-text8")}
                <Link to="/politica-cookies">
                  {t("Footer.politica-cookies-url")}
                </Link>
                .
              </p>
              <br />
              <h2>{t("Aviso-legal.legal-title5")}</h2>
              <br />
              {t("Aviso-legal.legal-text9")}
               
              <br />
              <h2>{t("Aviso-legal.legal-title6")}</h2>
              <br />
              <p>
                {t("Aviso-legal.legal-text10")}
                <Link to="/politica-privacidad">
                  {t("Footer.politica-privacidad-url")}
                </Link>
                .
              </p>{" "}
              <br />
              <h2>{t("Aviso-legal.legal-title7")}</h2>
              <br />
              <p>{t("Aviso-legal.legal-text11")}</p>
              <br />
              <h2>{t("Aviso-legal.legal-title8")}</h2> <br />
              <p>{t("Aviso-legal.legal-text12")}</p>
              <br />
              <h2>{t("Aviso-legal.legal-title9")}</h2>
              <br />
              <p>{t("Aviso-legal.legal-text13")}</p>
              <br />
              <h2>{t("Aviso-legal.legal-title10")}</h2>
              <br />
              <p>{t("Aviso-legal.legal-text14")}</p>
              <br />
            </div>
          </Col>
          <Col lg={3} sm={24} xs={24} span={8}></Col>
        </Row>
      </div>
      <Footer />
    </div>
  );
}
