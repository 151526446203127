import { Box, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

export default function Contact() {
  const [t] = useTranslation("global");
  const contactItems = [
    {
      icon: "/contactItems/chatbot.png",
      title: "Customer.chat",
      link: `${t("ChatBot.urlChat")}`,
    },
    {
      icon: "/contactItems/mail.png",
      title: "Customer.email",
      link: "mailto:{t('Customer.email')}",
    },
    // {
    //   icon: "contactItems/formulario.png",
    //   title: "Customer.formular",
    //   link: `https://echo.sg.cainiao.com/p/emailTask/officialForm/create?LAYOUT=NO&lang=${i18n.language}-ES&id=ecoscooting`,
    // },
    {
      icon: "/contactItems/phone.png",
      title: "Customer.phone",
    },
    {
      icon: "/contactItems/time.png",
      title: "Customer.time",
      link: "",
    },
  ];

  return (
    <>
      <div id="top"></div>
      <Box component="main" sx={{ p: 3, width: "100%", height: "50vh" }}>
        {/* <Toolbar /> */}
        <Box
          sx={{
            height: "70vh",
            width: "100%",
            display: "flex",
            flexWrap: { lg: "nowrap", xs: "wrap" },
            justifyContent: "center",
            alignItems: "center",
            columnGap: 10,
          }}
        >
          <Box
            component="img"
            src="/cs.png"
            alt="client-service-picture"
            sx={{
              width: "30%",
              mb: "7vw",
              ml: -10,
              display: { xs: "none", lg: "block" },
            }}
          />
          <Stack gap={{ xs: 5, lg: 5 }} sx={{ alignSelf: "start" }}>
            <Typography variant="h1" color="#0492fe" textAlign="center">
              {t("Header.help1")}
              <br />
              {t("Header.help2")}
            </Typography>
            <Box display="flex" flexDirection="column" gap={{ xs: 2, xl: 4 }}>
              {contactItems.map((item) => {
                return (
                  <Box display="flex" alignItems="center" gap={1}>
                    <Box
                      component="img"
                      src={item.icon}
                      alt="email"
                      width={30}
                    />
                    <Box
                      component={item.link ? "a" : "div"}
                      href={item.link}
                      sx={item.link ? { "&:hover": { opacity: "0.5" } } : {}}
                    >
                      <Typography variant="subtitle1">
                        {t(item.title)}
                      </Typography>
                    </Box>
                  </Box>
                );
              })}
            </Box>
          </Stack>
        </Box>
      </Box>
    </>
  );
}
