import { Box, Typography } from "@mui/material";
import SearchTracking from "./SearchTracking";
import { useBreakpoints } from "../../../app/hooks/useBreakpoints";
import { useTranslation } from "react-i18next";
import CustomInput from "../../../components/CustomInput";

export default function SearchBar() {
  const { lg, xl } = useBreakpoints();
  const [t, i18n] = useTranslation("global");
  const currLan = i18n.language;
  const isPt = currLan === "pt";

  return (
    <>
      {lg && <SmComponent t={t} xl={xl} isPt={isPt} />}
      {!lg && <XsComponent />}
    </>
  );
}

const XsComponent = ({ t, xl, currLan }) => {
  return (
    <Box
      position="absolute"
      width="100%"
      sx={{
        bottom: "5%",
      }}
    >
      <Box pl="20px" pr="20px" width="100%">
        <CustomInput />
      </Box>
    </Box>
  );
};

const SmComponent = ({ t, xl, isPt }) => {
  return (
    <Box sx={{ position: "absolute", top: "494px", width: "100vw" }}>
      <Box sx={{ position: "relative" }}>
        <Box
          component="img"
          src="/searchBg.png"
          sx={{
            minHeight: "130px",
            maxHeight: "130px",
            width: "100%",
          }}
        />
        <Typography
          variant="h4"
          color="white"
          sx={{
            position: "absolute",
            top: "10px",
            right: isPt ? "25vw" : "30vw",
          }}
        >
          {t("Tracking.track")}
        </Typography>
        <Box
          width={xl ? "227px" : "152px"}
          height="90px"
          bgcolor="#DEF0FF"
          sx={{ position: "absolute", top: "40px", right: 0, zIndex: -1 }}
        ></Box>
        <SearchTracking />
      </Box>
    </Box>
  );
};
